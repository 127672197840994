

export default class PageResponse  {
	Current: number;
	Size: number;
	Count: number;
	constructor(obj?: Partial<PageResponse>) {
		if (obj) {
			Object.assign(this, obj);
		}
	}
}
