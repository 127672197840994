
import { reactive, ref } from "vue";
import { Options, Vue } from "vue-class-component";

import DefaultModel from "tirscript3-component-models/src/defaultModel";
import LogsViewFilter from "@/views/components/filter-component/logs-view-filter.vue";

import UiPaginationComponent from "./tirscript3-table/tirscript3-pagination.vue";

import ListViewItem from "tirscript3-list-view/src/ListViewItem";
import api from "@/api";
// import DefaultModelCaps from "@/views/a-model/default-modelCaps";
// import GetProjectLogGroupsResponse from "@dataSource/LoggerMicroService/Services/Models/Response/GetProjectLogGroupsResponse";

@Options<Page>({
  name: "page",
  components: {
    LogsViewFilter,
  },
  props: {
    searchPlaceholder: { default: "Поиск", type: String },
    noApi: { default: false, type: Boolean },
    noFilter: { default: false, type: Boolean },
    defaultSearchField: String,
    noPagination: { default: false, type: Boolean },
    selectSource: { default: true, type: Boolean },
    name: {
      type: String,
      default: "Название страницы",
    },
  },
  watch: {
    filter(value) {
      this.innerItems = this.filter;
    },
  },
  emits: ["onAdd"],
})
export default class Page extends Vue {
  readonly searchPlaceholder: string = "Поиск";
  readonly name: string = "Название страницы";
  readonly noApi: boolean = false;
  readonly noFilter: boolean = false;
  readonly noPagination: boolean = false;

  sourceLogsList: ListViewItem<DefaultModel>[] = [];
  // id источника логов для фильтра
  sourceLogId = 0;
  filterChange = false;

  isLoading = false;
  totalItems = 0;
  currentPage = 1;
  innerItems: any[] = [];
  hasFilter = false;
  countPerPage = 10;
  pagination = true;
  created() {
    this.sourceLogsList = [
      new ListViewItem(new DefaultModel(-1, "Все источники"), true, false),
    ];
  }
  async mounted() {
    await this.getSource();
    var filter = this.sourceLogsList.filter((el) => el.Selected === true)[0];
    const selectedId = filter ? (filter.Value as DefaultModel).id : null;

    this.sourceLogId = selectedId;
  }
  async getSource() {
    let res = [];
    try {
      // res = await this.$api.ProjectLogGroupService.getProjectLogGroupsAsync();
    } catch (error) {
      console.log("error", error);
      return;
    }
    res.forEach((element) => {
      this.sourceLogsList.push(
        new ListViewItem(
          new DefaultModel(element.Id, `${element.ProjectName}/${element.ServerName}`),
          false,
          false
        )
      );
    });
    let sourceId = localStorage.getItem("source");
    if (sourceId) {
      this.sourceLogsList.forEach(
        (el) => (el.Selected = (el.Value as DefaultModel).id == Number.parseInt(sourceId))
      );
    }
  }

  onChangeSource(sourceLogId) {
    this.sourceLogId = sourceLogId;
  }
  onClick() {
    this.$emit("onAdd");
  }
  changeFilter() {
    // this.filterChange = !this.filterChange;
    document.dispatchEvent(
      new CustomEvent("filterChange", {
        detail: { mode: this.filterChange },
      })
    );
    // console.log(`this.filterChange`, this.filterChange);
  }
}
