import moment from "moment";

export default function getUTC(filter) {
    let filterCopy = JSON.parse(JSON.stringify(filter))
    let timezoneHour = new Date().getTimezoneOffset() / 60
    if (timezoneHour < 0) {
        timezoneHour = Math.abs(timezoneHour);
    } else {
        timezoneHour = -Math.abs(timezoneHour);
    }
    filterCopy.FromDate = moment(filterCopy.FromDate).add("hours", timezoneHour).toDate()
    filterCopy.ToDate = moment(filterCopy.ToDate).add("hours", timezoneHour).toDate()
    return filterCopy;
}