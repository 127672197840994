enum HttpMethodEnum {
	Get = 0,
	Post = 1,
	Put = 2,
	Delete = 3,
	Head = 4,
	Connect = 5,
	Options = 6,
	Trace = 7,
	Patch = 8
}
export default HttpMethodEnum
